import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { StyleSheet, Animated, ScrollView, View, Image, Text, TouchableOpacity, Dimensions, SafeAreaView, Linking } from 'react-native';

import colours from '../../config/colours';
import Header from '../../components/header'
import { useNavigation } from '@react-navigation/native';

import HomeCard from '../../components/homeCard';

import HomeCardBanner from '../../components/homeCardBanner';
import Footer from '../../components/footer'
import 'firebase/analytics'


const Home = () => {


  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth
  })
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }



  const { width, height } = dimensions;

  useEffect(() => {
    window.scrollTo(0, 0)
    window.addEventListener("resize", handleResize, false);
    return () => window.removeEventListener("resize", handleResize);

    //firebase.analytics();
    // eslint-disable-next-line
  }, [width, height]);

  const navigation = useNavigation();
  const [pageNumber, setPageNumber] = useState(0)

  const offset = useRef(new Animated.Value(0)).current;

  return (
    <SafeAreaView style={{ height: height, backgroundColor: colours.brand }}>

      <Header animatedValue={offset} pageNumber={pageNumber}/>

      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{ flexGrow: 1 }}
        pagingEnabled={true}
        showsVerticalScrollIndicator={false}
        scrollEventThrottle={16}
        // onScrollEndDrag={console.log('test',offset)}
        //onScroll={(event)=>{console.log('event test',event.nativeEvent.contentOffset.y)}}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { y: offset } } }],
          {
            useNativeDriver: false,
            listener: event => {
              const offsetY = event.nativeEvent.contentOffset.y
              // do something special  
              setPageNumber(offsetY / height)
              //console.log('test',offsetY==0?'first':offsetY/height)
            }
          }
        )}
      >






   <HomeCardBanner
          pageNumber={pageNumber}
          runValue={pageNumber == 0 ? true : false}
         bgcolor={'#ffffff'}
          title={'WE TRANSFORM PROJECTS'}
          subTitle={'Market Research, Marketing,Social Media, Web, Apps'}
          paragraph={'Velocitia is an technology consultancy that transforms projects by generating ideas, building products, and accelerating growth.'}
          size={'large'}
        />

        <HomeCard
          runValue={pageNumber == 1 ? true : false}
          bgcolor={'#2a6ddf'}
          title={'MARKET RESEARCH'}
          subTitle={'Feasibility Studies, Project Development Research & Studies'}
         paragraph={'Establish business & projects with our intensive reports'}
        />

        <HomeCard
          runValue={pageNumber == 2 ? true : false}
          bgcolor={'#34a1db'}
          title={'DIGITAL MARKETING'}
          subTitle={'Branding, Website, Social Media, Email, Lead Generation'}
          paragraph={'Team of experts that set the new standard for reaching, engaging and converting audiences'}
        />
        <HomeCard
          runValue={pageNumber == 3 ? true : false}
          bgcolor={'#796cc6'}
          title={'APPS'}
          subTitle={'iOS, Android, E-Commerce, Web'}
          paragraph={'Our development capabilities produce bespoke solutions that have horsepower and that solve real problems'}

        />
        <HomeCard
          runValue={pageNumber == 4 ? true : false}
          bgcolor={'#24a292'}
          title={'WE WORKED WITH..'}
          paragraph={'PDC Group, Amazon, ENBD, Abdul Samad Al Qurashi, OMSI Group,IDMEA, iCare Sports, Primeway Technologies'}

        />
        <HomeCard
          runValue={pageNumber == 5 ? true : false}
          bgcolor={'#d07f54'}
          title={"LET'S TALK"}
          paragraph={'Tell us about your project. We respond to almost every inquiry within one business day'}
          isButton={true}
          onPress={()=>navigation.navigate('Contact')}

        />

{pageNumber==5 &&

<Footer animatedValue={offset} pageNumber={pageNumber}/>
}





      </ScrollView>
    </SafeAreaView>


  );
};



const styles = StyleSheet.create({

  container: {
    flexGrow: 1,
  },

});


export default Home;
