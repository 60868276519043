import React, { useState, useEffect, useCallback } from 'react';
import { Easing, Animated, StyleSheet, View, Pressable, Button, Image, Text, TouchableOpacity, Linking, Dimensions, FlatList, ActivityIndicator, RefreshControl, ScrollView, SafeAreaView, ImageBackground } from 'react-native';

import colours from '../config/colours';

import { useNavigation } from '@react-navigation/native';
import fonts from '../config/fonts'


const { height, width } = Dimensions.get('window')

const HomeBannerBox = ({ reset, runValue, sequence, title, subtitle,BannerBoxImage }) => {


  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth
  })
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }





  const { width, height } = dimensions;
  const [fadeAnim, setFadeAnim] = useState(new Animated.Value(0));
  const animate = new Animated.Value(0);
  const inputRange = [0, 1];
  const translate1 = animate.interpolate({ inputRange, outputRange: [-20, 0] });
  const translate2 = animate.interpolate({ inputRange, outputRange: [20, 0] });




  const fadeAnimRun = () => {
    if (runValue) {
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 300,
        easing: Easing.linear
      }).start();

      Animated.timing(animate, {
        toValue: 1,
        duration: 400,
        easing: Easing.linear,
        useNativeDriver: true,
      }).start();

    } else {
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: 300,
        easing: Easing.linear,
      }).start();

      Animated.timing(animate, {
        toValue: 0,
        duration: 400,
        useNativeDriver: true,
        easing: Easing.linear
      }).start();
    }

  }




  useEffect(() => {
    setTimeout(
      function () {
        fadeAnimRun();
      }, 500 * sequence)

    window.scrollTo(0, 0)
    window.addEventListener("resize", handleResize, false);
    return () => window.removeEventListener("resize", handleResize);

    //firebase.analytics();
    // eslint-disable-next-line
  }, [width, height,runValue]);




  const navigation = useNavigation();


  const styles = StyleSheet.create({

    containerBanner: {
      flex: 1,
      flexDirection: 'row',
      marginBottom: 20,
      borderWidth: width > 1000 ? 5:2,
      borderColor: colours.brand,
     //borderColor: colours.blackSecondary,
      paddingVertical: 15,
      marginHorizontal: width > 1000 ? 25 : 5,
  
    },
    containerImage:{
      height:width>1000?70:40,
      width:width>1000?70:40,
      alignSelf:'center',
      marginBottom:width>1000?10:4,
    },
    containerTop: {
      flex: 1,
      justifyContent:'flex-start'
    },
    containerBottom: {
      flex: 1,
      justifyContent:'flex-end',
      alignSelf:'center',
      marginVertical:4,
  
    },
    containerTitle: {
      flex: 1,
     // alignSelf: 'center',
      maxWidth: 800,
    },
    textTitle: {
      color: colours.blackSecondary,
      fontWeight: '700',
      textAlign: 'center',
      //fontSize: width > 1000 ? 20 : 15,
      fontSize:width > 1000 ?fonts.sizeH2:fonts.sizeH2M,
      padding: 5,
      fontFamily: fonts.secondary,
      lineHeight: 17,
  
    },
    textSubTitle: {
      color: colours.blackSecondary,
      fontWeight: '300',
      textAlign: 'center',
      //fontSize: width > 1000 ? 16 : 14,
      fontSize:width > 1000 ?fonts.sizeP:fonts.sizePM,
      padding: 5,
      fontFamily: fonts.primary
    },
    textButton: {
     // color: colours.white,
      fontWeight: '500',
      textAlign: 'center',
      justifyContent: 'flex-end',
      fontSize:width > 1000 ?fonts.sizeP:fonts.sizePM,
      paddingVertical: 7,
      paddingHorizontal: 10,
  
      //color: '#ae0201',
      margin: 4,
      fontFamily: fonts.primary,
      backgroundColor:colours.brandLightExtra,
    },
    hovered: {
      backgroundColor: colours.blackSecondary,
      //borderRadius: 4,
      //padding:5
    },
  });

  return (


    <Animated.View style={[styles.containerBanner, { 
      //backgroundColor: bgcolor,
      opacity: fadeAnim 
      },
      { transform: [{ translateY: translate1 }] }]}>
      <View style={styles.containerTitle}>

      <View style={styles.containerTop}>
        <Image source={BannerBoxImage} style={styles.containerImage} />
        <Text textBreakStrategy='1' adjustsFontSizeToFit={true} style={styles.textTitle}>{title}</Text>
        <Text style={styles.textSubTitle}>{subtitle} {'\n'}</Text>

        </View>

        {/* <View style={styles.containerBottom}>
        <Pressable style={(state) => state.hovered && styles.hovered} >
          <TouchableOpacity //</Pressable>onPress={() =>  navigation.navigate('Services')}
          >
            <Text style={styles.textButton}> EXPLORE</Text>
          </TouchableOpacity>
        </Pressable>
        </View> */}



      </View>
    </Animated.View>


  );

  
};





export default HomeBannerBox;