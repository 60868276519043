import React, { useEffect, useState } from 'react'
import { Easing,Text, StyleSheet, ScrollView, Animated, View, TouchableOpacity, Dimensions, ImageBackground } from 'react-native'
import colours from '../config/colours'
import fonts from '../config/fonts'

import VelocitiaArrow from '../assets/images/logo/arrow.svg'
//const { width, height } = Dimensions.get('window')


const HomeCard = ({ cardTopImage, title, subTitle,paragraph, onPress, bgcolor, type = 'filled', isButton, size = 'large', runValue }) => {


  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth
  })
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }




  const { width, height } = dimensions;
  const [fadeAnim, setFadeAnim] = useState(new Animated.Value(0));
  const [animate, setAnimate] = useState(new Animated.Value(0));

  //const animate = new Animated.Value(0);
  const inputRange = [0, 1];
  const translate1 = animate.interpolate({ inputRange, outputRange: [-20, 0] });
  const translate2 = animate.interpolate({ inputRange, outputRange: [200, 0] });
  const translate3 = animate.interpolate({ inputRange, outputRange: [-200, 0] });
  const translate4 = animate.interpolate({ inputRange, outputRange: [200, 0] });


  const fadeAnimRun = () => {
    if (runValue) {
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 300,
        easing: Easing.linear
      }).start();

      Animated.timing(animate, {
        toValue: 1,
        duration: 400,
        easing: Easing.linear,
        useNativeDriver: true,
      }).start();


    } else {
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: 500,
        easing: Easing.linear,
      }).start();

      Animated.timing(animate, {
        toValue: 0,
        duration: 400,
        easing: Easing.linear,
        useNativeDriver: true,
      }).start();
    }
  }

  useEffect(() => {
    fadeAnimRun();

    window.scrollTo(0, 0)
    window.addEventListener("resize", handleResize, false);
    return () => window.removeEventListener("resize", handleResize);

    //firebase.analytics();
    // eslint-disable-next-line
  }, [width, height, runValue]);





  const large = width
  const small = width > 1000 ? 400 : width - 20
  const btnSize = size === 'large' ? large : small
  const btnBgColor = type === 'filled' ? colours.brandDark : colours.greyFirst

  const bGColor = bgcolor === 'brand' ? colours.brandDark : bgcolor

  const btnSubTextColor = type === 'filled' ? colours.white : colours.white
  const btnTextColor = type === 'filled' ? colours.white : colours.white

  const containerCommonStyle = {
    backgroundColor: bGColor,
    width: btnSize,
    height: height,

  }


  const titleCommonStyle = {
    paddingTop: 15,
    paddingHorizontal: 20,
    paddingBottom: 5,
    color: btnTextColor,
    fontSize: width>1000?75:55,
    fontWeight: '700',
    fontFamily: fonts.secondary,
    textAlign: 'center',
    transform: [{ translateY: translate1}]

  }
  const subTitleCommonStyle = {
    paddingTop: 5,
    paddingHorizontal: 20,
    paddingBottom: 15,
    color: btnSubTextColor,
    fontSize: width>1000?30:20,
    textAlign: 'center',
    fontFamily: fonts.primary,
    transform: [{ translateY: translate2 }]

  }

  const paraCommonStyle = {
    color: btnSubTextColor,
    fontSize: width>1000?22:18,
    textAlign: 'center',
    fontFamily: fonts.primary,
    fontWeight:'300',
    paddingHorizontal: width>1000?20:7,


  }


  return (
    <View style={[containerCommonStyle]}>
      <ImageBackground source={cardTopImage} style={{ height: '100%' }}  >

          <View style={[styles.containerText, { height: height }]}>
            <Animated.View style={{ opacity: fadeAnim, }} >
              
            <Animated.Text style={titleCommonStyle }>
              {title}
              </Animated.Text>


              <Animated.Image source={VelocitiaArrow} style={{
                width:60,
                height:15,
                alignSelf:'center',
              transform: [{ translateX: runValue?translate3:translate4}] 
              }} />
 



              <TouchableOpacity onPress={onPress} activeOpacity={0.7}>
              <Animated.Text adjustsFontSizeToFit={true} style={subTitleCommonStyle}>{subTitle}</Animated.Text>
              </TouchableOpacity>
  

              <Animated.Text  style={[paraCommonStyle, {transform: [{ translateY: translate2 }] }]}>
              {paragraph}
              </Animated.Text>
{isButton &&

              <TouchableOpacity onPress={onPress} activeOpacity={0.7}>
              <Animated.Text adjustsFontSizeToFit={true} style={[paraCommonStyle,{fontWeight:'700' ,backgroundColor:'rgba(0,0,0,0.2)',padding:7,alignSelf:'center',borderRadius:5,marginTop:5}]}>{'Contact Now'}</Animated.Text>
              </TouchableOpacity>

}

            </Animated.View>

          </View>

      </ImageBackground>


    </View>
  )
}

export default HomeCard


const styles = StyleSheet.create({


  containerMain: {
    backgroundColor: colours.black,
  },
  containerText: {
   // textAlignVertical: 'center',
    //flexDirection: 'column',
    justifyContent: 'center',
  }


});