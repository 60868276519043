
import React from 'react';



import { NavigationContainer, DarkTheme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';


import Home from './home/home' 

import colours from '../config/colours';
import Contact from '../components/contact';

import UnderConstruction from '../components/underConstruction';
import UC from '../components/uc';
import PromotionPage from './promo/promotionPage';




const HomeStack = createNativeStackNavigator();
function HomeStackScreen({route, navigation}) {


  return (
    <HomeStack.Navigator screenOptions={{ headerShown: false}}>
      <HomeStack.Screen name='Home' component={Home} options={{headerShown: false,title:'Velocitia'}}/>
      <HomeStack.Screen name='Contact' component={Contact} options={{headerShown: false,title:'Velocitia'}}/>
      <HomeStack.Screen name='Under Construction' component={UC} options={{headerShown: false,title:'Velocitia'}}/>
      <HomeStack.Screen name='Promotion' component={PromotionPage} options={{headerShown: false,title:'Smart Business Card'}}/>


      {/* <HomeStack.Screen name='About' component={About} options={{headerShown: false}}/> */}


    </HomeStack.Navigator>
  );
}

const linking = {
  prefixes: ['https://velocitia.com', 'velocitia://','localhost:3000'],
  config: {
    screens: {
      Home: '',
      About: 'about',
      Contact: 'contact',
      Services: 'services',
      Service: 'service',
      Promotion: 'promotion',


    }
  },
};
const MyTheme = {
  ...DarkTheme,
  colors: {
    ...DarkTheme.colors,
    background: colours.brand,
  },
};

function HomeNavigator() {
  
  return (
    
    <NavigationContainer linking={linking} fallback={<Text>Loading...</Text>} theme={MyTheme}>
      <HomeStackScreen />
    </NavigationContainer>
    
  )
}

export default HomeNavigator;
