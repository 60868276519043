import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Animated, StyleSheet, View, Pressable, Button, Image, Text, TouchableOpacity, Linking, Dimensions, FlatList, ActivityIndicator, RefreshControl, ScrollView, SafeAreaView } from 'react-native';

import colours from '../config/colours';
import VelocitiaLogo from '../assets/images/logo/velocitia.svg'
import VelocitiaLogoW from '../assets/images/logo/velocitia_w.svg'

import MenuHamburger from '../assets/images/menu.svg'
import MenuHamburgerB from '../assets/images/menu_B.svg'

import Call from '../assets/images/social/call.svg'
import Callw from '../assets/images/social/callw.svg'

import Modal from 'react-native-modal';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useNavigation } from '@react-navigation/native';
import fonts from '../config/fonts'
import Menu from './menu';
import { ImageBackground } from 'react-native-web';
// const { height, width } = Dimensions.get('window')

const Header = ({ animatedValue,pageNumber }) => {

  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth
  })
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }



  const {width, height} = dimensions;

  useEffect(() => {
    window.scrollTo(0, 0)
    window.addEventListener("resize", handleResize, false);
    return () => window.removeEventListener("resize", handleResize);

    //firebase.analytics();
    // eslint-disable-next-line
  }, [width, height]);


  const HEADER_HEIGHT = width > 1000 ? 110 : 75;
  const HEADER_HEIGHTM = width > 1000 ? 60 : 45;
  const HEADER_PADDING = width > 1000 ? 25 : 15;;


  const insets = useSafeAreaInsets();
  const headerHeight = animatedValue.interpolate({
    inputRange: [0, HEADER_HEIGHT + insets.top],
    outputRange: [HEADER_HEIGHT + insets.top, insets.top + HEADER_HEIGHTM],
    extrapolate: 'clamp'
  });

  const headerPadding = animatedValue.interpolate({
    inputRange: [0, HEADER_PADDING + insets.top],
    outputRange: [HEADER_PADDING + insets.top, insets.top + 0],
    extrapolate: 'clamp'
  });


  
  const navigation = useNavigation();
  const [isModalVisibleContact, setModalVisibleContact] = useState(false);
  const toggleModal = () => {
    setModalVisibleContact(!isModalVisibleContact);
  };

//STYLES
const styles = StyleSheet.create({
  container: {
 //  backgroundColor:'rgba(255,0,0,0.1)',
 
    position: 'absolute',
    top: 0,
    
  },
  containerCenter: {
    //maxWidth:1365,
    alignSelf: 'center'

  },
  containerMain: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  containerLogo: {
    //paddingLeft: width > 1000 ? 20 : 5,

  },

  containerMenu: {
    // paddingRight: 20,
    flexDirection: 'row',
    
  },
  containerMenuMobile: {
    flexDirection: 'row',
    alignItems:'center',
    justifyContent:'center'
  },
  containerMenuLeft: {
    flex:1,
    alignItems:'flex-start',
    //justifyContent: 'center',
    //backgroundColor:'green'
  },
  containerLogoM: {
   flex:4,
    justifyContent: 'center',
  alignItems:'center',
    //backgroundColor:'blue'
  },
  containerMenuRight: {
    flex:1,
    //justifyContent: 'center',
    alignItems:'flex-end',
  //  backgroundColor:'yellow'
  },

  containerMenuText: {
    color: pageNumber==0?colours.blackSecondary:colours.white,
    fontWeight: '400',
    fontSize: 20,
    marginHorizontal: 10,
     paddingVertical: 5,
    fontFamily: fonts.primary
  },
  hovered: {
    backgroundColor: 'rgba(255,0,0,0.2)',
    borderRadius: 4,
    //paddingHorizontal:5,

  },
  modalContainer: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: colours.white,
    justifyContent: 'flex-start',
    width:'80%',
    //width:width>1000? 800: width-20,
    //alignSelf:'center',
    //paddingVertical:40,
    margin: 0,
  },

});
//STYLES


  return (
    <Animated.View style={[styles.container, {
      width: width,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 10,
      paddingHorizontal: headerPadding,
      // paddingBottom:headerHeight*.2,
      height: headerHeight,
      flexDirection: 'row',
      justifyContent: 'center',
      backgroundColor:pageNumber>0?'rgba(0,0,0,0.1)':'rgba(255,0,0,0.1)',
    }]}>
      <View style={[styles.containerCenter,{    width: width > 1000 ? '85%' : '95%',
}]}>


        <View >

          {width > 1000 &&
        <View style={styles.containerMain}>
              <View style={styles.containerLogo}>
                <TouchableOpacity onPress={() => navigation.navigate('Home')}>
                  <img src={pageNumber>0?VelocitiaLogoW:VelocitiaLogo} width={pageNumber>0?180:200} height={100}/>
                </TouchableOpacity>
              </View>

              <View style={styles.containerMenu}>
                <Pressable onPress={() => navigation.navigate('Under Construction') } style={(state) => state.hovered && styles.hovered} >
                    <Text style={styles.containerMenuText}> {'ABOUT'} </Text>
                </Pressable>
                <Pressable onPress={() => navigation.navigate('Under Construction') } style={(state) => state.hovered && styles.hovered} >
                    <Text style={styles.containerMenuText}> SERVICES</Text>
                </Pressable>

                <Pressable onPress={() => navigation.navigate('Under Construction') } style={(state) => state.hovered && styles.hovered} >

                  <Text style={styles.containerMenuText}> CLIENTS</Text>
                </Pressable>

                <Pressable onPress={() => navigation.navigate('Promotion') } style={(state) => state.hovered && styles.hovered} >

                  <Text style={styles.containerMenuText}>SMART BUSINESS CARD</Text>
                </Pressable>

                <Pressable onPress={() => navigation.navigate('Contact') }
                  style={(state) => state.hovered && styles.hovered}
                >              

                    <Text style={styles.containerMenuText}> CONTACT</Text>
                </Pressable>



              </View>
            </View>
          }





          {width < 1000 &&
            <View style={styles.containerMenuMobile}>

              <View style={styles.containerMenuLeft}>
                <TouchableOpacity onPress={() => { setModalVisibleContact(!isModalVisibleContact) }}>
                  <img src={pageNumber>0?MenuHamburger:MenuHamburgerB} width={40} height={30} />
                </TouchableOpacity>
              </View>
              <View style={styles.containerLogoM}>
                <TouchableOpacity onPress={() => navigation.navigate('Home')}>
                  <img src={pageNumber>0?VelocitiaLogoW:VelocitiaLogo} width={140} height={pageNumber>0?30:40} />
                </TouchableOpacity>
              </View>

              <View style={styles.containerMenuRight}>
                <TouchableOpacity 
                onPress={() => navigation.navigate('Contact')}
                >
                  <img src={pageNumber>0?Call:Callw} width={35} height={27} />
                </TouchableOpacity>
              </View>

            </View>
          }






        </View>




        <Modal
          isVisible={isModalVisibleContact}
          transparent={true}
          onBackdropPress={() => setModalVisibleContact(!isModalVisibleContact)}
          animationIn={'slideInLeft'}
          animationOut={'slideOutLeft'}
          style={styles.modalContainer}
        >
          <View>
            <Menu
              toggleModal={toggleModal}
            />
          </View>
        </Modal>


      </View>
    </Animated.View>

  );
};






export default Header;