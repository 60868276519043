import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { ScrollView, StyleSheet, Animated, TextInput, View, Image, Text, TouchableOpacity, Dimensions, SafeAreaView, Linking } from 'react-native';

import colours from '../../config/colours';
import Header from '../../components/header'
import { useNavigation } from '@react-navigation/native';

import HomeCard from '../../components/homeCard';
import Whatsapp from '../../assets/images/social/Whatsapp.svg'
import Promo1 from '../../assets/promo/smartbusinesscard/Smartcard Ad 01.jpg'
import Promo2 from '../../assets/promo/smartbusinesscard/Smartcard Ad 02.jpg'
import Promo3 from '../../assets/promo/smartbusinesscard/Smartcard Ad 03.jpg'

import HomeCardBanner from '../../components/homeCardBanner';
import Footer from '../../components/footer'
import 'firebase/analytics'
import { firebase } from '../../config/firebase'
import 'typeface-montserrat'


const { height, width } = Dimensions.get('window')

const PromotionPage = () => {

    const whatsappURL = 'https://api.whatsapp.com/send?phone=971543017184'

    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    })
    const handleResize = () => {
        setDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }



    const { width, height } = dimensions;

    useEffect(() => {
        window.scrollTo(0, 0)
        window.addEventListener("resize", handleResize, false);
        return () => window.removeEventListener("resize", handleResize);

        //firebase.analytics();
        // eslint-disable-next-line
    }, [width, height]);

    const navigation = useNavigation();
    const [pageNumber, setPageNumber] = useState(0)

    const offset = useRef(new Animated.Value(0)).current;



    const PageTexts = {
        title1: 'Smart Business Card',
        title2: 'Request a call back ',

        paragraph1: 'Wireless + QR, Smart Features with Advance web app page and many more.. \n\n',
        paragraph2: 'Email: info@velocitia.com',

    }




    const [onFocus, setOnFocus] = useState(false)

    const [onFocusNumber, setOnFocusNumber] = useState(false)

    const [onFocusMessage, setOnFocusMessage] = useState(false)

    const [nameText, setNameText] = useState('')
    const [numberText, setNumberText] = useState('')
    const [messageText, setMessageText] = useState('')

    //data
    const [companyName, setCompanyName] = useState('')
    const [companyPhone, setCompanyPhone] = useState('')
    const [companyMobile, setCompanyMobile] = useState('')



    const [onFocusCompanyName, setOnFocusCompanyName] = useState('')
    const [onFocusCompanyPhone, setOnFocusCompanyPhone] = useState('')
    const [onFocusCompanyMobile, setOnFocusCompanyMobile] = useState('')



    const contactUsData = () => {

        var currentDate = new Date();
        var twoDigitMonth = ((currentDate.getMonth() + 1) >= 10) ? (currentDate.getMonth() + 1) : '0' + (currentDate.getMonth() + 1);
        var twoDigitDate = ((currentDate.getDate()) >= 10) ? (currentDate.getDate()) : '0' + (currentDate.getDate());
        var createdDateTo = currentDate.getFullYear() + twoDigitMonth + twoDigitDate;
        //const docid = createdDateTo + "-" + new Date().getTime()
         const docid = (new Date().getTime()).toString()
        firebase.firestore().collection('leads')
            .doc(docid).set({
                ad: 'Smart Business Card',
                category: 'New',
                date: (new Date()).toISOString(),
                platform: 'Landing Page',
                name: companyName,
                contact: companyPhone,
                whatsapp: companyMobile,
                readstatus: false,
                id:new Date().getTime(),
                info:''
            })
            .then(alert('Details Sent Successfully,\nWe will contact you as soon as possible. \nThank You'))
            .then(setCompanyName(''), setCompanyPhone(''), setCompanyMobile(''))
    }





    return (
        <View style={{ height: height }}>

            <Header animatedValue={offset} pageNumber={pageNumber} />
            <ScrollView
                style={{ flex: 1 }}
                contentContainerStyle={{ flexGrow: 1 }}
                pagingEnabled={true}
                showsVerticalScrollIndicator={false}
                scrollEventThrottle={16}
                // onScrollEndDrag={console.log('test',offset)}
                //onScroll={(event)=>{console.log('event test',event.nativeEvent.contentOffset.y)}}
                onScroll={Animated.event(
                    [{ nativeEvent: { contentOffset: { y: offset } } }],
                    {
                        useNativeDriver: false,
                        listener: event => {
                            const offsetY = event.nativeEvent.contentOffset.y
                            // do something special  
                            setPageNumber(offsetY / height)
                            //console.log('test',offsetY==0?'first':offsetY/height)
                        }
                    }
                )}
            >


                <View style={styles.container}>
                    <View style={styles.containerHeaderBG}>


                    </View>

                    {/* <PageBanner
                cardTopImage={{ uri: aboutHeader }}
                title={'Registration'}
            /> */}


                    <View style={styles.containerBodyPage}>
                        <Text style={[styles.textHeader, { borderBottomWidth: 2, borderColor: colours.brand }]}>{PageTexts.title1}</Text>
                        <Text style={styles.textParagraph}>{PageTexts.paragraph1}</Text>
                        <Text style={[styles.textHeader, { borderTopWidth: 1, borderBottomWidth: 1, color: colours.brand, borderColor: colours.brand }]}>{PageTexts.title2}</Text>

                    </View>






                    <View style={styles.containerForm}>

                        <View style={styles.textContainer}>

                            <TextInput
                                adjustsFontSizeToFit={true}
                                allowFontScaling={true}
                                value={companyName}
                                placeholder={onFocus ? '' : 'Name*'}
                                onChangeText={(editedText) => { setCompanyName(editedText) }}
                                onFocus={() => { setOnFocusCompanyName(true) }}
                                onBlur={() => { setOnFocusCompanyName(false) }}
                                placeholderTextColor={colours.greyFive}
                                style={[styles.textInputStyle, { borderColor: onFocus ? colours.blackSecondary : colours.greyFour }]}
                            />
                            <TextInput
                                adjustsFontSizeToFit={true}
                                allowFontScaling={true}
                                value={companyPhone}
                                placeholder={onFocusNumber ? '' : 'Mobile Number*'}
                                keyboardType='numeric'
                                maxLength={15}
                                onChangeText={(editedText) => { setCompanyPhone(editedText) }}
                                onFocus={() => { setOnFocusCompanyPhone(true) }}
                                onBlur={() => { setOnFocusCompanyPhone(false) }}
                                placeholderTextColor={colours.greyFive}
                                style={[styles.textInputStyle, { borderColor: onFocusNumber ? colours.blackSecondary : colours.greyFour }]}
                            />

                            <TextInput
                                adjustsFontSizeToFit={true}
                                allowFontScaling={true}
                                value={companyMobile}
                                placeholder={onFocus ? '' : 'WhatsApp Number*'}
                                onChangeText={(editedText) => { setCompanyMobile(editedText) }}
                                onFocus={() => { setOnFocusCompanyMobile(true) }}
                                onBlur={() => { setOnFocusCompanyMobile(false) }}
                                placeholderTextColor={colours.greyFive}
                                style={[styles.textInputStyle, { borderColor: onFocus ? colours.blackSecondary : colours.greyFour }]}
                            />



                            <TouchableOpacity
                                onPress={() => {
                                    companyName.length < 3 ? alert('Error ! Please Check Name') :
                                        isNaN(companyPhone) ? alert('Error ! Please Check Phone Number') :
                                            companyPhone.length < 5 ? alert('Error ! Please Check Phone Number') :

                                                contactUsData()

                                }
                                }
                            >
                                <View style={styles.submitButton}>
                                    <Text style={styles.textSubmit}>SUBMIT REQUEST</Text>
                                </View>
                            </TouchableOpacity>
                        </View>

                        <View style={styles.containerBodyPage}>
                            <Text style={[styles.textParagraph, { textAlign: 'center' }]}>{PageTexts.paragraph2}</Text>



                            <TouchableOpacity style={styles.submitButton} onPress={() => Linking.openURL(whatsappURL)}>
                                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
                                    <Text style={[styles.textSubmit, { paddingTop: 5 }]}>{'Chat in WhatsApp   '}
                                    </Text>
                                    <img src={Whatsapp} width={35} style={{ marginBottom: 5 }} /> </View>
                            </TouchableOpacity>


                            <Image source={Promo1} style={styles.containerImage} />
                            <Image source={Promo2} style={styles.containerImage} />
                            <Image source={Promo3} style={styles.containerImage} />

                        </View>

{/* CALL BACK STARTS */}


<View style={styles.containerBodyPage}>
                        <Text style={[styles.textHeader, { borderTopWidth: 1, borderBottomWidth: 1, color: colours.brand, borderColor: colours.brand, alignSelf:'center'}]}>{PageTexts.title2}</Text>

                    </View>






                    <View style={styles.containerForm}>

                        <View style={styles.textContainer}>

                            <TextInput
                                adjustsFontSizeToFit={true}
                                allowFontScaling={true}
                                value={companyName}
                                placeholder={onFocus ? '' : 'Name*'}
                                onChangeText={(editedText) => { setCompanyName(editedText) }}
                                onFocus={() => { setOnFocusCompanyName(true) }}
                                onBlur={() => { setOnFocusCompanyName(false) }}
                                placeholderTextColor={colours.greyFive}
                                style={[styles.textInputStyle, { borderColor: onFocus ? colours.blackSecondary : colours.greyFour }]}
                            />
                            <TextInput
                                adjustsFontSizeToFit={true}
                                allowFontScaling={true}
                                value={companyPhone}
                                placeholder={onFocusNumber ? '' : 'Mobile Number*'}
                                keyboardType='numeric'
                                maxLength={15}
                                onChangeText={(editedText) => { setCompanyPhone(editedText) }}
                                onFocus={() => { setOnFocusCompanyPhone(true) }}
                                onBlur={() => { setOnFocusCompanyPhone(false) }}
                                placeholderTextColor={colours.greyFive}
                                style={[styles.textInputStyle, { borderColor: onFocusNumber ? colours.blackSecondary : colours.greyFour }]}
                            />

                            <TextInput
                                adjustsFontSizeToFit={true}
                                allowFontScaling={true}
                                value={companyMobile}
                                keyboardType='numeric'
                                placeholder={onFocus ? '' : 'WhatsApp Number*'}
                                onChangeText={(editedText) => { setCompanyMobile(editedText) }}
                                onFocus={() => { setOnFocusCompanyMobile(true) }}
                                onBlur={() => { setOnFocusCompanyMobile(false) }}
                                placeholderTextColor={colours.greyFive}
                                style={[styles.textInputStyle, { borderColor: onFocus ? colours.blackSecondary : colours.greyFour }]}
                            />



                            <TouchableOpacity
                                onPress={() => {
                                    companyName.length < 3 ? alert('Error ! Please Check Name') :
                                        isNaN(companyPhone) ? alert('Error ! Please Check Phone Number') :
                                            companyPhone.length < 5 ? alert('Error ! Please Check Phone Number') :

                                                contactUsData()

                                }
                                }
                            >
                                <View style={styles.submitButton}>
                                    <Text style={styles.textSubmit}>SUBMIT REQUEST</Text>
                                </View>
                            </TouchableOpacity>
                        </View>

                        <View style={styles.containerBodyPage}>



                            <TouchableOpacity style={styles.submitButton} onPress={() => Linking.openURL(whatsappURL)}>
                                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
                                    <Text style={[styles.textSubmit, { paddingTop: 5 }]}>{'Chat in WhatsApp   '}
                                    </Text>
                                    <img src={Whatsapp} width={35} style={{ marginBottom: 5 }} /> </View>
                            </TouchableOpacity>
                            <Text style={[styles.textParagraph, { textAlign: 'center' }]}>{PageTexts.paragraph2}</Text>

                            </View>
                            </View>

{/* CALL BACK ENDS */}


                    </View>






                    <View style={styles.containerFooter}>
                        <Footer />
                    </View>



                </View>
            </ScrollView>
        </View>


    );
};



const styles = StyleSheet.create({
    container: {
        flex: 1,
        minHeight: '100vh',
        backgroundColor: colours.greyBG,
    },
    containerHeaderBG: {
        backgroundColor: colours.black,
        marginTop: 110
    },

    containerHeader: {

        width: width > 1000 ? 1280 : width,
        alignItems: 'space-around',
        alignSelf: 'center',
        justifyContent: 'flex-start',
        backgroundColor: colours.black
    },

    containerBody: {
        //flex: 1,
        paddingVertical: width > 1000 ? 20 : 5,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignSelf: 'center',
        alignItems: 'center',
        width: width > 1000 ? 1280 : width,
    },
    containerBodyPage: {
        alignSelf: 'center',
        // marginVertical: 50,
        backgroundColor: colours.blueGreyLight,
        paddingTop: 25,
        paddingBottom: 50,

        width: width > 1000 ? 1100 : width - 20,
        //maxWidth:1280,
        marginHorizontal: 80,
        paddingHorizontal: width > 1000 ? 100 : 25,
        //shadowColor: colours.black,
        //shadowOffset: { width: 0, height: 3, },
        //shadowOpacity: 0.2,
        //shadowRadius: 20,
        //elevation: 5,
    },
    textHeader: {
        paddingTop: 20,
        paddingBottom: 10,
        color: colours.black,
        fontSize: 18,
        fontWeight: '500',
        fontFamily: 'montserrat',
        textAlign: 'justify',
    },
    textParagraph: {
        paddingVertical: 10,
        color: colours.greyDark,
        fontSize: 15,
        lineHeight: 22,
        textAlign: 'justify',
        fontFamily: 'montserrat',
    },
    textSubHeader: {
        paddingTop: 10,
        paddingBottom: 10,
        color: colours.black,
        fontSize: 15,
        fontWeight: '500',
        fontFamily: 'montserrat',
        textAlign: 'justify',
    },
    containerImage: {
        flex: 1,
        height: 300,
        alignSelf: 'center'
    },
    containerImagePortrait: {
        flex: 1,
        height: 300,
    },

    Image: {
        height: 300,
        width: 200,
        resizeMode: 'contain',
        marginHorizontal: 10,
        // marginVertical:width>1000?25:5,  
    },
    TextImageLabel: {
        color: colours.greyDark,
        fontSize: 14,
        textAlign: 'center',
        fontFamily: 'montserrat',
    },
    containerForm: {
        width: width > 1000 ? 1100 : width - 20,

        alignSelf: 'center',
        marginBottom: 20,
        paddingBottom: 10,
        backgroundColor: colours.blueGreyLight,

    },

    textContainer: {
        paddingTop: 10,
        marginHorizontal: 10,
        alignItems: 'center',
        backgroundColor: colours.blueGreyLight,

    },

    textSubmit: {
        fontSize: 20,
        color: colours.white,
    },

    submitButton: {
        backgroundColor: colours.brandDark,
        alignItems: 'center',
        paddingHorizontal: 20,
        paddingVertical: 10,
        marginTop: 10,
        borderRadius: 5,

    },
    containerFooter: {
        flex: 1,
        justifyContent: 'flex-end',
    },
    textInputStyle: {
        minWidth: width > 1000 ? 700 : width - 30,
        //maxWidth: '100%',
        textAlign: 'left',
        fontFamily: 'montserrat',
        marginBottom: 10,
        marginTop: 2,
        color: colours.greyDark,
        fontSize: '.8rem',
        fontWeight: '500',
        borderWidth: 1,
        borderRadius: 5,
        padding: 10
    },
    containerImage:{
        height: width > 1000?800:width-20, 
        width: width > 1000?800:width-20, 
        alignSelf:'center',
        marginVertical:10
    }

});


export default PromotionPage;
