

import "typeface-montserrat"
import "typeface-lato"
import "typeface-raleway"
import "typeface-roboto"
import "typeface-oswald"


const fonts = {
  primary	: 'lato',
  secondary	: 'oswald',
  sizeBig:'9vmin',
  sizeBigM:'7vmax',

  sizeH1:'3vmin',
  sizeH1M:'2vmax',

  sizeH2:'2.8vmin',
  sizeH2M:'2vmax',

  sizeH3:'2.2vmin',
  sizeH3M:'1.3vmax',

  sizeH4:'2vmin',
  sizeH4M:'1.2vmax',

  sizeP:'2.5vmin',
  sizePM:'1.7vmax',

  sizeSmall:'2vmin',
  sizeSmall:'1.5vmax'

  };

  export default fonts;
  