// Colours
const colours = {
brand:'#d50000',
brandDark:'#7f0000',
brandLight:'#f05545',
brandLightExtra:'#ffebee',

black:'#000000',
blackSecondary:'#212121',
white:'#ffffff',
greyBG:'#d3d3d3',

blueGreyLight:'#eceff1',
indigoLight:'#e8eaf6',
brownLight:'#efebe9',

greyLight:'#fafafa',
greyFirst:'#f5f5f5',
greySecond:'#eeeeee',
greyThird:'#e0e0e0',
greyFour:'#bdbdbd',
greyFive:'#9e9e9e',
greyDark:'#707070'

};
export default colours;
