import React,{useState} from 'react';
import { ScrollView,StyleSheet,View,TextInput, Image, Text, TouchableOpacity, Dimensions,SafeAreaView,Linking} from 'react-native';
import Modal from 'react-native-modal';
import colours from '../config/colours'
import { useNavigation } from '@react-navigation/native';
import { firebase } from '../config/firebase'
//import Whatsapp from '../assets/images/Whatsapp.svg'
import 'typeface-montserrat'



const { height, width } = Dimensions.get('window')

const ContactForm = () => {
  const whatsappURL = 'https://api.whatsapp.com/send?phone=971503599486'

  const [loading, setLoading] = useState(false);


    
    const [fullName, setFullName] = useState();
    const [messageText, setMessageText] = useState();
    const [contactText, setcontactText] = useState();
    const [mobileText, setMobileText] = useState('');
    const [errorModal,setErrorModal]=useState(false)
    const [errorText,setErrorText]=useState('')
    const [messageTextHeight, setMessageTextHeight] = useState(120);
   



    var currentDate = new Date();
    var twoDigitMonth=((currentDate.getMonth()+1)>=10)? (currentDate.getMonth()+1) : '0' + (currentDate.getMonth()+1);  
    var twoDigitDate=((currentDate.getDate())>=10)? (currentDate.getDate()) : '0' + (currentDate.getDate());
    var createdDateTo = currentDate.getFullYear() + twoDigitMonth + twoDigitDate; 
    const docid = createdDateTo+"-"+new Date().getTime()


    const saveMessages = async () => {
      setLoading(true);
      try{
      let initialQuery = await firebase.firestore().collection('contactform')
      .doc(docid)
      .set({
                Email: contactText, 
                Mobile:mobileText, 
                Name: fullName, 
                Messages:messageText, 
                MessageReceiveTime:firebase.firestore.Timestamp.fromDate(new Date()),
      },{merge: true})
      .then(alert('Thank You! A Velocitia Team member will be contacting you soon.'))}
      catch (e){console.log('errors',e)}
      setLoading(false);setMessageText(''),setcontactText(''),setMobileText(''),setFullName('')

    }
    
    

return (
<View>

        <View style={styles.formContainer}>
                    <Text style={[styles.textFormHeader,{fontSize:'2rem',fontWeight:'700'}]} >
                      Get in Touch {'\n'}
                      <Text style={styles.textFormHeader} >
                      Use the form below to drop us an email.
                    </Text>
                    </Text>

        <TextInput style={styles.textInput}
                onChangeText={(editedText) => {setFullName(editedText)}}
                value={loading?'':fullName}
                placeholder={'Name'}
                placeholderTextColor={colours.forthGrey}

            />


        <TextInput style={styles.textInput}
                onChangeText={(editedText) => {setcontactText(editedText)}}
                value={loading?'':contactText}
                placeholder={'Email'}
                placeholderTextColor={colours.forthGrey}
            />
        <TextInput style={styles.textInput}
                onChangeText={(editedText) => {setMobileText(editedText)}}
                keyboardType='numeric'
                value={loading?'':mobileText}
                placeholder={'Contact Number'}
                placeholderTextColor={colours.forthGrey}

            />


        <TextInput style={[styles.textInput,{height:messageTextHeight}]}
                adjustsFontSizeToFit={true}  
                allowFontScaling={true} 
                onContentSizeChange={(event) => {setMessageTextHeight(event.nativeEvent.contentSize.height)}}
                onChangeText={(editedText) => {setMessageText(editedText)}}
                multiline={true}
                value={loading?'':messageText}
                placeholder={'Write Your Message Here'}
                placeholderTextColor={colours.forthGrey}

            />


        <TouchableOpacity
                onPress={()=> { 
                messageText? 
                  contactText?
                  mobileText?
                  isNaN(mobileText)? [setErrorModal(true),setErrorText('Please provide correct mobile number')]:
                  mobileText.length<9? [setErrorModal(true),setErrorText('Please provide correct mobile number')]:
                  fullName?
                  [saveMessages()]
                    //,console.log('success')]
                  :[setErrorModal(true),setErrorText('Please provide Name')]
                  :[setErrorModal(true),setErrorText('Please provide Mobile No')]
                  :[setErrorModal(true),setErrorText('Please provide Email')]
                  :[setErrorModal(true),setErrorText('Please provide Message')]
                  
                  }}
                >      <View style={styles.registerButtonBox}>
                            <Text  style={styles.registerButtonBoxText} >
                              Send
                            </Text>
                      </View>
          </TouchableOpacity>

        </View>


<Modal
    isVisible={errorModal} 
    transparent={true}
    onBackdropPress={()=>setErrorModal(false)}
    animationIn={'fadeIn'}
    animationOut={'fadeOut'}
    style={styles.modalContainer}
    >
        <Text style={styles.textModal}>
          {errorText}</Text> 


            <TouchableOpacity 
                onPress={()=> setErrorModal(!errorModal)}
                style={styles.buttonModal}
                    >

                      <Text style={{
                        paddingHorizontal:15,
                        paddingVertical:10,
                        fontWeight:'600',
                        alignSelf:'center',
                        color:'white'}
                        }>Close</Text> 

                </TouchableOpacity>

  </Modal>




</View>

    );
  };



const styles = StyleSheet.create({
  formContainer: {
   // backgroundColor:colours.primaryBlack,
    alignItems: 'center',
    paddingVertical: 25,
    maxWidth: 1200,
  },

  textFormHeader: {
    fontSize: 17,
    fontWeight: '400',
    color: colours.blackSecondary,
    textAlign: 'center',
    marginVertical: 10,
    fontFamily: 'montserrat'
  },


  textInput: {
    fontSize: 17,
    fontWeight: '400',
    color: '#000',
    marginVertical: 7,
    paddingHorizontal: 15,
    paddingVertical:10,
    backgroundColor: colours.brandLightExtra,
    borderRadius: '.6rem',
    minWidth: width > 1000 ? 500 : '80vw',
    maxWidth: 700,
    textAlign: 'left',
    marginBottom: 5,
    fontFamily: 'montserrat'
  },

  modalContainer: {
    alignItems: 'center',
    //backgroundColor: colours.brandLightExtra,
    borderRadius: 20,
    padding: 25,
    justifyContent: 'center'
  },
  textModal: {
    color: colours.primaryWhite,
   // backgroundColor: colours.white,
    marginBottom: 25,
    textAlign: 'center',
    fontWeight: '500',
    fontSize: '1rem'
  },
  buttonModal:
  {
    backgroundColor: 'darkred',
    borderRadius: 10,
    paddingVertical: 3,
    paddingHorizontal: 10
  },


  registerButtonBox: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: colours.brandLight,
    borderRadius: '.4rem',
    height: 50,
    paddingHorizontal: 20,
    paddingVertical:10,
    marginHorizontal: 20,
    marginVertical:10

  },

  registerButtonBoxText: {
    fontSize: '1.2rem',
    fontWeight: '800',
    color: colours.blackSecondary,
    marginHorizontal: 10,
    fontFamily: 'montserrat'

  },



});
  

export default ContactForm;
  
  


