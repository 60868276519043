import React, { useEffect, useState } from 'react'
import { Easing, Text, StyleSheet, ScrollView, Animated, View, TouchableOpacity, Dimensions, ImageBackground } from 'react-native'
import colours from '../config/colours'
import fonts from '../config/fonts'
import HomeBannerBox from './homeBannerBox'
import BannerBoxImage1 from '../assets/images/home/banners/marketresearch.png'
import BannerBoxImage2 from '../assets/images/home/banners/marketing.png'
import BannerBoxImage3 from '../assets/images/home/banners/apps.png'
import VelocitiaArrow from '../assets/images/logo/arrow.svg'


//const { width, height } = Dimensions.get('window')


const HomeCardBanner = ({  title, subTitle, paragraph, onPress, bgcolor, type = 'filled',  size = 'large', runValue, pageNumber }) => {


    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    })
    const handleResize = () => {
        setDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }




    const { width, height } = dimensions;
    const [fadeAnim, setFadeAnim] = useState(new Animated.Value(0));
    const [animate, setAnimate] = useState(new Animated.Value(0));
    const inputRange = [0, 1];
    const translate1 = animate.interpolate({ inputRange, outputRange: [-20, 0] });
    const translate2 = animate.interpolate({ inputRange, outputRange: [20, 0] });
    const translate3 = animate.interpolate({ inputRange, outputRange: [-500, 0] });
    const translate4 = animate.interpolate({ inputRange, outputRange: [200, 0] });


    const fadeAnimRun = () => {
        if (runValue) {
            Animated.timing(fadeAnim, {
                toValue: 1,
                duration: 300,
                easing: Easing.linear
            }).start();

            Animated.timing(animate, {
                toValue: 1,
                duration: 1000,
                easing: Easing.linear,
                useNativeDriver: true,
            }).start();

        } else {
            Animated.timing(fadeAnim, {
                toValue: 0,
                duration: 300,
                easing: Easing.linear,
            }).start();

            Animated.timing(animate, {
                toValue: 0,
                duration: 400,
                useNativeDriver: true,
                easing: Easing.linear
            }).start();
        }
    }

    useEffect(() => {
        fadeAnimRun();
        window.scrollTo(0, 0)
        window.addEventListener("resize", handleResize, false);
        return () => window.removeEventListener("resize", handleResize);

        //firebase.analytics();
        // eslint-disable-next-line
    }, [width, height, runValue]);





    const large = width
    const small = width > 1000 ? 400 : width - 20
    const btnSize = size === 'large' ? large : small



    const containerCommonStyle = {
        backgroundColor: bgcolor,
        width: btnSize,
        height: height,

    }


    const titleCommonStyle = {
        paddingTop: 15,
        paddingHorizontal: 20,
        paddingBottom: 5,
        color: colours.blackSecondary,
        fontSize: width>1000?fonts.sizeBig:fonts.sizeBigM,
        fontWeight: '700',
        fontFamily: fonts.secondary,
        textAlign: 'center',

    }
    const subTitleCommonStyle = {
        paddingTop: 5,
        paddingHorizontal: 20,
        paddingBottom: 15,
        color: colours.blackSecondary,
        fontSize: width>1000?fonts.sizeH2:fonts.sizeH2M,
        textAlign: 'center',
        fontFamily: fonts.primary,

    }

    const paraCommonStyle = {
        color: colours.blackSecondary,
        fontSize: width>1000?fonts.sizeP:fonts.sizePM,
        textAlign: 'center',
        fontFamily: fonts.primary,
        fontWeight: '300'
    }


    return (
        <View style={[containerCommonStyle]}>

            

                <View style={[styles.containerText, { height: height }]}>
                    <Animated.View style={{ 
                        opacity: fadeAnim,
                        justifyContent:'center',
                        flex:1
                     }} >

                        <Animated.Text style={[titleCommonStyle, { transform: [{ translateY: translate1 }] }]}>
                            {title}
                        </Animated.Text>

                        {/* <TouchableOpacity onPress={onPress} activeOpacity={0.7}>
                            <Text adjustsFontSizeToFit={true} style={[subTitleCommonStyle]}>{subTitle}</Text>
                        </TouchableOpacity> */}

              <Animated.Image source={VelocitiaArrow} style={{
                width:60,
                height:15,
                alignSelf:'center',
              transform: [{ translateX: runValue?translate3:translate4}] 
              }} />
                        

                        <Animated.Text style={[paraCommonStyle, { transform: [{ translateY: translate2 }] }]}>
                            {paragraph}
                        </Animated.Text>
 
                    </Animated.View>



                    <Animated.View style={[styles.containerBannerBoxMain, { marginHorizontal: width > 1000 ? 25 : 10,opacity: fadeAnim, }]}>

                        <HomeBannerBox
                            reset={fadeAnim}
                            runValue={pageNumber == 0 ? true : false}
                            BannerBoxImage={BannerBoxImage1}
                            sequence={0}
                            title={'MARKET RESEARCH'}
                            subtitle={'Research & Feasibility Studies'}
                        />
                        <HomeBannerBox
                            runValue={pageNumber == 0 ? true : false}
                            BannerBoxImage={BannerBoxImage2}
                            sequence={1}
                            title={'DIGITAL MARKETING'}
                            subtitle={'Branding, Website, Social Media, Lead Generation'}
                        />
                        <HomeBannerBox
                            runValue={pageNumber == 0 ? true : false}
                            BannerBoxImage={BannerBoxImage3}
                            sequence={2}
                            title={'APPS'}
                            subtitle={'iOS, Android, E-Commerce, Web Apps'}
                        />

                    </Animated.View>



                </View>
        </View>
    )
}

export default HomeCardBanner


const styles = StyleSheet.create({


    containerMain: {
        backgroundColor: colours.black,
    },
    containerText: {
      // paddingTop:140,
        paddingHorizontal:7,
       // justifyContent: 'space-between',
        
    },
    containerBannerBoxMain: {
        flexDirection: 'row',

    }


});