

import React, { useState,useContext } from 'react';
import { ScrollView, View, Text, Dimensions, StyleSheet,TouchableOpacity } from "react-native";
import colours from '../config/colours'
import { useNavigation } from '@react-navigation/native';
import 'typeface-montserrat'
import ContactForm from './contactForm';
import Connect from './connect';
import UnderConstruction from './underConstruction';

const { height, width } = Dimensions.get('window')

const UC = () => {
    const navigation = useNavigation();

    const [isModalVisibleContact, setModalVisibleContact] = useState(false);

    const toggleModal = () => {
      setModalVisibleContact(!isModalVisibleContact);
    };

return(
 
  <View style={styles.menuContainerMain}>



                <TouchableOpacity 
                onPress={()=>navigation.navigate('Home')} 
                               // onPress={()=>navigation.goBack()} 

                >
                    
                    <View style={styles.headerItemLeft}>
                    <Text style={styles.closeText}>X</Text>
                    </View>

                    </TouchableOpacity>


<Connect />
<UnderConstruction />
{/* 
 <View style={styles.containerRow}>
</View> */}

  </View>

    )};

export default UC;

const styles = StyleSheet.create({

    menuContainerMain: {
        minHeigh:height,
        backgroundColor:colours.white,
        maxWidth:width
    },

    menuContainer: {
        alignSelf:'center',
        alignItems:'center',
    },

    closeText: {
        fontSize: 27,
        //color: colours.primaryWhite,
        fontFamily:'montserrat',
        borderWidth:1,
        borderRadius:10,
        borderColor:colours.brandColorDark,
        paddingHorizontal:15,

    },

    menuText: {
        marginVertical: 20,
        //color: colours.primaryWhite,
        fontSize: 25,
        fontFamily:'montserrat'
    },
    containerRow: {
      flex:1,
      alignItems:'center'
  },

    headerItemLeft:
    {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginVertical: 10,
      marginHorizontal:width>1000?10:10,
      alignSelf:'flex-end',
      paddingHorizontal:1,
      paddingVertical:3,
      borderRadius:2,
      borderColor:colours.primaryLight
  
    },



});


