import React, { useEffect } from 'react';
import { StyleSheet, View, Image, Text, TouchableOpacity, Dimensions, SafeAreaView, Linking } from 'react-native';


import { useNavigation } from '@react-navigation/native';

import 'firebase/analytics'

import EmailIcon from '../assets/images/social/Email.svg'
import Whatsapp from '../assets/images/social/Whatsapp.svg'
import PhoneIcon from '../assets/images/social/phone.svg'



const { height, width } = Dimensions.get('window')

const Connect = () => {

  const whatsappURL = 'https://api.whatsapp.com/send?phone=971543017184'



  const navigation = useNavigation();

  return (



    <View style={styles.followContainer}>



      <View style={styles.followContainerItems}>

        <Text accessibilityRole="link" href={whatsappURL} target="_blank" ><img src={Whatsapp} width={50} /> </Text>
      </View>



      <View style={styles.followContainerItems}>


        <Text accessibilityRole="link" href={'mailto:hello@velocitia.com'} target="_blank" ><img src={EmailIcon} width={50} /> </Text>
      </View>

      <View style={styles.followContainerItems}>


        <Text accessibilityRole="link" href={'tel:+971543017184'} ><img src={PhoneIcon} width={50} /></Text>
      </View>

    </View>


  );
};



const styles = StyleSheet.create({



  followContainer: {
    flex: 1,
    flexDirection: 'row',
    marginVertical: 15,
    alignSelf: 'center',
    // paddingBottom:10,
  },

  followContainerItems: {
    flex: 1,
    marginHorizontal: 10,
    alignContent: 'center',

  },

});


export default Connect;




