

import React, { useState, useEffect,useContext } from 'react';
import { ScrollView ,View, Text, Dimensions, StyleSheet,TouchableOpacity,Linking } from "react-native";
import colours from '../config/colours'
import { useNavigation } from '@react-navigation/native';
import fonts from '../config/fonts';

const { height, width } = Dimensions.get('window')

const Menu = ({ route,  toggleModal}) => {
    const navigation = useNavigation();

  const [isModalVisibleContact, setModalVisibleContact] = useState(false);
  const [isModalVisible, setModalVisible] = useState(true);













  const goHome = () => {
    setModalVisible(!isModalVisible);
    navigation.goBack();
  };
  const Home =() => {
    navigation.navigate('Home');
    setModalVisible(!isModalVisible);

  }
return(
 
    <ScrollView >



                <TouchableOpacity 
                //onPress={() => {Home(),toggleModal()}} 
                onPress={toggleModal} 
                >
                    
                    <View style={styles.headerItemLeft}>
                    <Text style={styles.closeText}>X</Text>
                    </View>

                    </TouchableOpacity>


<View style={styles.menuMainContainer}>


  <TouchableOpacity
    onPress={() => {toggleModal() ,navigation.navigate('Under Construction')}} 

  >
  <View style={styles.menuContainer}>
            <Text style={styles.menuText}> ABOUT </Text>
  </View>
  </TouchableOpacity>

  <TouchableOpacity 
    onPress={() => {toggleModal() ,navigation.navigate('Under Construction')}} 
    >
  <View style={styles.menuContainer}>
            <Text style={styles.menuText}> SERVICES </Text>
  </View>
  </TouchableOpacity>

  <TouchableOpacity 
    onPress={() => {toggleModal() ,navigation.navigate('Under Construction')}} 
    >
  <View style={styles.menuContainer}>
            <Text style={styles.menuText}> CLIENTS </Text>
  </View>
  </TouchableOpacity>




  <TouchableOpacity
    onPress={() => {toggleModal() ,navigation.navigate('Promotion')}} 

  >
  <View style={styles.menuContainer}>
            <Text style={styles.menuText}> SMART BUSINESS CARD</Text>
  </View>
  </TouchableOpacity>


  <TouchableOpacity
    onPress={() => {toggleModal() ,navigation.navigate('Contact')}} 

  >
  <View style={styles.menuContainer}>
            <Text style={styles.menuText}> CONTACT</Text>
  </View>
  </TouchableOpacity>
  
</View>

   
            </ScrollView >

    )};

export default Menu;

const styles = StyleSheet.create({

    menuContainer: {
     // paddingHorizontal:7,
    },
    menuMainContainer: {
      paddingHorizontal:10,
      paddingTop:40,
    },
    closeContainer: {
       // backgroundColor: colours.primary,
       // borderRadius: 5,
        marginTop:20,
        paddingHorizontal: 20,
        paddingVertical: 20,
        alignSelf:'center',
    },
 
    closeText: {
        fontSize: '1.7rem',
        fontWeight:'400',
        color: colours.blackSecondary,
        fontFamily: fonts.primary,

    },



    menuText: {
        marginVertical: '.6rem',
        //marginHorizontal: 20,
        textAlign:'left',
        color: colours.blackSecondary,
        fontSize: '2rem',
        fontWeight: '500',
        fontFamily: fonts.primary,

    },
    menuTextCS: {
      marginVertical: '.6rem',
      //marginHorizontal: 20,
      color: colours.primaryLight,
      fontSize: '1.2rem',
      fontWeight: '300',
      fontFamily: fonts.primary,

  },
    headerItemLeft:
    {
      //flex: 2,
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginVertical: 20,
      marginHorizontal:width>1000?40:20,
     // borderWidth:1,
      alignSelf:'flex-start',
      paddingHorizontal:10,
      paddingVertical:3,
      borderRadius:3,
      borderColor:colours.primaryLight
  
    },
    containerLogo:  {
        //paddingLeft:20,
        alignItems:'center',
        marginBottom:10,
      },


});


