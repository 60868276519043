import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Easing,Animated,StyleSheet, View, Button, Image, Text, TouchableOpacity, Dimensions, FlatList, ActivityIndicator, RefreshControl, ScrollView, SafeAreaView, Linking } from 'react-native';

import colours from '../config/colours';
import fonts from '../config/fonts'
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useNavigation } from '@react-navigation/native';



const Footer = ({pageNumber}) => {


  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth
  })
  const handleResize = () => {
    console.log('footer')
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  const FadeAnimate=()=>{
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 2000,
    }).start();
  }



  const {width, height} = dimensions;
  const [fadeAnim, setFadeAnim] = useState(new Animated.Value(0));

  useEffect(() => {
    FadeAnimate();
    
    window.scrollTo(0, 0)
    window.addEventListener("resize", handleResize, false);
    return () => window.removeEventListener("resize", handleResize);

    //firebase.analytics();
    // eslint-disable-next-line
  }, [width, height]);


  const navigation = useNavigation();




  return (
    <Animated.View style={[styles.containerFooter, {
      opacity: fadeAnim,
      flexDirection:width > 1000 ?'row':'column',
    justifyContent:width > 1000 ?'space-between':'center',
    width: width,
    }]}>
 

<View style={[styles.containerLeft,{paddingVertical:width > 1000 ?10:7,
    paddingHorizontal:width > 1000 ?20:10}]}>

<TouchableOpacity onPress={() => navigation.navigate('Contact')}>
          <Text style={styles.footerLink} >
            DUBAI
          </Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => navigation.navigate('Contact')}>
          <Text style={styles.footerLink} >
            LONDON
          </Text>
        </TouchableOpacity>
</View>



<View style={[styles.containerRight,{paddingVertical:width > 1000 ?10:7,
    paddingHorizontal:width > 1000 ?20:10
    }]}>


      <Text numberOfLines={1} style={[styles.footerText,{fontSize: width > 1000 ? 15 : 12}]} >
        <TouchableOpacity onPress={() => Linking.openURL('mailto:hello@velocitia.com')}>
          <Text style={[styles.footerLink,{fontSize: width > 1000 ? 15 : 12}]}>hello@velocitia.com</Text>
        </TouchableOpacity>   © {new Date().getFullYear()} Copyright

        <TouchableOpacity onPress={() => Linking.openURL('https://velocitia.com/privacy')}>
          <Text style={[styles.footerLink,{fontSize: width > 1000 ? 15 : 12}]}>Privacy Policy</Text>
        </TouchableOpacity> 


      </Text>
    </View>

    </Animated.View>

  );
};



const styles = StyleSheet.create({
  containerLeft: {
    flexDirection: 'row',
    alignItems:'center',
    justifyContent:'center',

  },
  containerRight: {
    flexDirection: 'row',
    alignSelf:'center',

  },
  containerFooter: {
    flex: 1,

    backgroundColor: colours.brandDark,
    position: 'absolute',
    bottom: 0,
    paddingTop: 5,
    paddingBottom: 10,

  },
  footerText: {
    color: colours.white,
    textAlign: 'center',
    fontWeight: '300',
    fontFamily: fonts.primary
  },

  footerLink: {
    fontWeight: '500',
    fontFamily: fonts.primary,
    color: colours.white,
    paddingHorizontal: 18
  },


});


export default Footer;