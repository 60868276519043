

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA4HQC_vt6JX4PQrzAhiAXioqOhUp6PEps",
  authDomain: "govelocitia.firebaseapp.com",
  projectId: "govelocitia",
  storageBucket: "govelocitia.appspot.com",
  messagingSenderId: "836733343980",
  appId: "1:836733343980:web:1726553a2886785e706dc9",
  measurementId: "G-S7Q0ZPEZ4L"
};


if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

export { firebase };